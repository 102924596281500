import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";

import Container from "components/Container";
import { wixEatabitAppUrl } from "constants/wix";

const mock = [
  {
    title: "Basic",
    subtitle: "Perfect for small operations",
    price: { monthly: "$25", annual: "$264" },
    features: [
      "Unlimited printing",
      "2.4Ghz wifi compatable",
      "30-day free trial",
      "24hr email support",
      "Automatic printing",
    ],
    isHighlighted: false,
  },
  // {
  //   title: "Pro",
  //   subtitle: "For more advanced workflows",
  //   price: { monthly: "$35", annual: "$360" },
  //   features: [
  //     "Unlimited printing",
  //     "2.4Ghz wifi compatable",
  //     "30-day free trial",
  //     "24hr email support",
  //     "Accept & Reject orders",
  //   ],
  //   isHighlighted: true,
  // },
];

const PricingOptions = ({ authenticated }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [pricingOption, setPricingOption] = useState("annual");

  const handleClick = (event, newPricingOption) => {
    event.preventDefault();
    setPricingOption(newPricingOption);
  };

  const handlePurchaseClick = (event) => {
    event.preventDefault();

    if (authenticated) {
      navigate("/user/account");
    } else {
      window.location = wixEatabitAppUrl;
    }
  };

  const renderToggler = () => (
    <Box display={"flex"} justifyContent={"center"} marginBottom={4}>
      <ToggleButtonGroup value={pricingOption} exclusive onChange={handleClick}>
        <ToggleButton
          value="annual"
          size={isMd ? "large" : "small"}
          sx={{
            backgroundColor:
              pricingOption === "annual"
                ? `${theme.palette.primary.light} !important`
                : "transparent",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption === "annual" ? "common.white" : "text.primary",
            }}
          >
            Annual
          </Typography>
        </ToggleButton>
        <ToggleButton
          value="monthly"
          size={isMd ? "large" : "small"}
          sx={{
            backgroundColor:
              pricingOption === "monthly"
                ? `${theme.palette.primary.light} !important`
                : "transparent",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption !== "annual" ? "common.white" : "text.primary",
            }}
          >
            Monthly
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );

  return (
    <Box>
      <Box sx={{ position: "relative" }}>
        <Container position={"relative"} zIndex={3}>
          <Box>
            <Box marginBottom={4}>
              <Typography
                variant="h3"
                gutterBottom
                align={"center"}
                sx={{
                  fontWeight: 900,
                }}
              >
                Flexible subscription options
              </Typography>
              <Typography
                variant="h6"
                component="p"
                color="text.primary"
                align={"center"}
              >
                Printers require an active Eatabit subscription plan.
                <br />
                Save <strong>12%</strong> when you select the annual plan.
              </Typography>
            </Box>
            {renderToggler()}
          </Box>
        </Container>
      </Box>
      <Container>
        <Grid container spacing={4} justifyContent={"center"}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={6} key={i}>
              <Box
                component={Card}
                height={1}
                display={"flex"}
                flexDirection={"column"}
                variant={"outlined"}
              >
                <CardContent
                  sx={{
                    padding: 4,
                  }}
                >
                  <Box marginBottom={2}>
                    <Typography variant={"h4"} fontWeight={600} gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography color={"text.secondary"}>
                      {item.subtitle}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"baseline"}
                    marginBottom={2}
                  >
                    <Typography variant={"h3"} fontWeight={700}>
                      {pricingOption === "annual"
                        ? item.price.annual
                        : item.price.monthly}
                    </Typography>
                    <Typography
                      variant={"subtitle1"}
                      color={"text.secondary"}
                      fontWeight={700}
                    >
                      {pricingOption === "annual" ? "/yr" : "/mo"}
                    </Typography>
                  </Box>
                  <Grid container spacing={1}>
                    {item.features.map((feature, j) => (
                      <Grid item xs={12} key={j}>
                        <Box
                          component={ListItem}
                          disableGutters
                          width={"auto"}
                          padding={0}
                        >
                          <Box
                            component={ListItemAvatar}
                            minWidth={"auto !important"}
                            marginRight={2}
                          >
                            <Box
                              component={Avatar}
                              bgcolor={theme.palette.primary.main}
                              width={20}
                              height={20}
                            >
                              <svg
                                width={12}
                                height={12}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Box>
                          </Box>
                          <ListItemText primary={feature} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ justifyContent: "flex-end", padding: 4 }}>
                  <Button
                    variant={"contained"}
                    size={"large"}
                    onClick={handlePurchaseClick}
                  >
                    {authenticated ? "Upgrade" : "Purchase"}
                  </Button>
                </CardActions>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

PricingOptions.propTypes = {
  authenticated: PropTypes.bool.isRequired,
};

export default PricingOptions;
