import React from "react";

import { withSessionContext } from "contexts/sessionContext";
import { Loading } from "shared/Loading";
import { Purchase, Processing, Printer } from "./components";

const PrinterState = ({ accountData }) => {
  if (!accountData || Object.keys(accountData).length === 0) {
    return <Loading />;
  }

  const {
    attributes: { paid },
    relationships: {
      printer: {
        data: { id: printerId },
      },
    },
  } = accountData;

  if (paid) {
    return printerId ? <Printer accountData={accountData} /> : <Processing />;
  }

  return <Purchase />;
};

export default withSessionContext(PrinterState);
