import React from "react";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import Container from "components/Container";
import { wixRestaurantsProductUrl, wixEatabitAppUrl } from "constants/wix";

const errors = {
  PermissionRestaurantsOrders: {
    title: "Missing app",
    description:
      'It looks like the "Wix Restaurants Orders" app is not installed for your site. Click the button below to install.',
    resolution: "Install Wix Restaurants",
    resolutionUrl: { type: "window", target: wixRestaurantsProductUrl },
  },
  InstallTimeout: {
    title: "Timeout",
    description:
      "It looks like your installation window has timed out. Please try installing the Eatabit app again.",
    resolution: "Install Wix Eatabit app",
    resolutionUrl: { type: "window", target: wixEatabitAppUrl },
  },
  AccountExists: {
    title: "Eatabit App already installed",
    description:
      "It looks like the Eatabit app is already installed. Try accessing the Eatabit app from your Wix Dashboard.",
    resolution: "Wix Dashboard",
    resolutionUrl: { type: "window", target: wixEatabitAppUrl },
  },
};

const Error = ({ wixError }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const handleOnClick = (event) => {
    event.preventDefault();

    errors[wixError].resolutionUrl.type === "window"
      ? (window.location = errors[wixError].resolutionUrl.target)
      : navigate(errors[wixError].resolutionUrl.target);
  };

  return (
    <Container>
      <Grid container>
        <Grid
          item
          container
          alignItems={"center"}
          justifyContent={"center"}
          xs={12}
          md={6}
        >
          <Box>
            <Typography
              variant="h1"
              component={"h1"}
              align={isMd ? "left" : "center"}
              sx={{ fontWeight: 700 }}
            >
              Error
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.primary"
              align={isMd ? "left" : "center"}
            >
              {errors[wixError].title}
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              align={isMd ? "left" : "center"}
              sx={{ mt: theme.spacing(2) }}
            >
              {errors[wixError].description}
            </Typography>
            <Box
              marginTop={4}
              display={"flex"}
              justifyContent={{ xs: "center", md: "flex-start" }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleOnClick}
              >
                {errors[wixError].resolution}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

Error.propTypes = {
  wixError: PropTypes.string.isRequired,
};

export default Error;
