import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Buffer } from "buffer";

import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { withSessionContext } from "contexts/sessionContext";
import { Loading } from "shared/Loading";
import { Search, Jobs } from "./components";
import { paths } from "constants/urls";
import { per } from "constants/pagination";

const { REACT_APP_WIX_API } = process.env;

const JobsState = ({ jobsData, setJobsData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const params = new URLSearchParams(location.search);
  const terms = params.get("terms") || "";

  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [searchParams, setSearchParams] = useState({
    terms,
    page: 0,
    sortOn: "",
    sortDir: "",
    per,
  });

  const wixInstanceId = localStorage.getItem("wixInstanceId");
  const Authorization = Boolean(wixInstanceId)
    ? "Bearer " + new Buffer.from(wixInstanceId).toString("base64")
    : null;

  useEffect(() => {
    if (!Boolean(Authorization)) {
      navigate("/sign-out");
      return;
    }

    const url = new URL(REACT_APP_WIX_API + paths.jobs);

    Object.keys(searchParams).forEach((k) =>
      url.searchParams.append(k, searchParams[k])
    );

    fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        return response.json();
      })
      .then(({ data, meta: { count } }) => {
        setJobsData(data);
        setMeta({ count });
        setLoading(false);
      })
      .catch((status) => {
        console.log("ERROR: ", status);
        // navigate("/sign-out");
      });
  }, [Authorization, searchParams, navigate, setJobsData]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Typography variant={"h4"} sx={{ p: theme.spacing(4) }}>
          Jobs
        </Typography>
        <Divider />
      </Grid>
      <Grid item container>
        <Search
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          setLoading={setLoading}
        />
      </Grid>
      <Grid item container>
        {loading ? (
          <Loading />
        ) : (
          <Jobs
            jobsData={jobsData}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            meta={meta}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default withSessionContext(JobsState);
