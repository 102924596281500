import React from "react";

import Box from "@mui/material/Box";

import User from "layouts/User";
import { JobsState } from "./components";

const UserJobs = () => {
  return (
    <User>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 247px)"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        height={1}
      >
        <JobsState />
      </Box>
    </User>
  );
};

export default UserJobs;
