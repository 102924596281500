import React from "react";
import { Link as RouterLink } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { company } from "constants/company";

const { REACT_APP_HTTP_SCHEME, REACT_APP_WWW_HOST } = process.env;

const Footer = () => {
  const theme = useTheme();

  const { mode } = theme.palette;

  const now = new Date();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={1}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box
            display={"flex"}
            component={RouterLink}
            to={"/"}
            title={company.name}
            width={80}
          >
            <Box
              component={"img"}
              src={
                mode === "light"
                  ? `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/logos/32_120_light.svg`
                  : `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/logos/32_120_dark.svg`
              }
              height={1}
              width={1}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={"center"}
          variant={"subtitle2"}
          color="text.secondary"
          gutterBottom
        >
          &copy; {company.name}. {now.getFullYear()}, All rights reserved
        </Typography>
        <Typography
          align={"center"}
          variant={"caption"}
          color="text.secondary"
          component={"p"}
        >
          When you visit or interact with our sites, services or tools, we or
          our authorised service providers may use cookies for storing
          information to help provide you with a better, faster and safer
          experience and for marketing purposes.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
