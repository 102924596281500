import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

import Container from "components/Container";

const Paper = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Container>
      <Box>
        <Grid container>
          <Grid item xs={12} md={5}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box>
                  <Typography
                    variant="h1"
                    component={"h1"}
                    align={isMd ? "left" : "center"}
                    sx={{ fontWeight: 700 }}
                    color={"secondary"}
                  >
                    Success
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    color="text.secondary"
                    align={isMd ? "left" : "center"}
                  >
                    Your corder has been received and we will notify you when it
                    ships.
                    <br />
                    If you need assistance, please{" "}
                    <Link
                      href={"mailto:support@eatabit.com?subject=Wix Order question"}
                      underline="none"
                    >
                      email us
                    </Link>
                  </Typography>
                  <Box
                    marginTop={4}
                    display={"flex"}
                    justifyContent={{ xs: "center", md: "flex-start" }}
                  >
                    <Button
                      component={RouterLink}
                      variant="contained"
                      color="primary"
                      size="large"
                      to={"/user"}
                    >
                      Back home
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Paper;
