import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

import Main from "layouts/Main";
import Container from "components/Container";

const SignOut = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up("md"), {
      defaultMatches: true,
    });

  useEffect(() => {
    localStorage.removeItem("wixInstanceId");
  }, []);

  return (
    <Main>
      <Box
        bgcolor={theme.palette.alternate.main}
        position={"relative"}
        minHeight={"calc(100vh - 247px)"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        height={1}
        marginTop={-12}
        paddingTop={12}
      >
        <Container>
          <Grid container>
            <Grid
              item
              container
              alignItems={"center"}
              justifyContent={"center"}
              xs={12}
              md={6}
            >
              <Box>
                <Typography
                  variant="h1"
                  component={"h1"}
                  align={isMd ? "left" : "center"}
                  sx={{ fontWeight: 700 }}
                >
                  Signed out
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  color="text.secondary"
                  align={isMd ? "left" : "center"}
                >
                  To sign back in, open the Eatabit app in the Wix dashboard.
                </Typography>
                <Box
                  marginTop={4}
                  display={"flex"}
                  justifyContent={{ xs: "center", md: "flex-start" }}
                >
                  <Button
                    component={RouterLink}
                    variant="contained"
                    color="primary"
                    size="large"
                    to={"/"}
                  >
                    Wix Dashboard
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

export default SignOut;
