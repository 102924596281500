import React from "react";

import {
  NotFound,
  UserJobs,
  UserAccount,
  UserTicketCreate,
  UserBuyPaper,
  UserPrinter,
  SignOut,
  Terms,
  Pricing,
  Install,
  UserCheckoutSuccess,
  SignIn,
} from "views";

const routes = [
  {
    path: "/install",
    renderer: (params = {}) => <Install {...params} />,
  },
  {
    path: "/pricing",
    renderer: (params = {}) => <Pricing {...params} />,
  },
  {
    path: "/sign-out",
    renderer: (params = {}) => <SignOut {...params} />,
  },
  {
    path: "/terms",
    renderer: (params = {}) => <Terms {...params} />,
  },
  {
    path: "/privacy",
    renderer: (params = {}) => <Terms {...params} />,
  },
  {
    path: "/user/account",
    renderer: (params = {}) => <UserAccount {...params} />,
  },
  {
    path: "/user",
    renderer: (params = {}) => <UserJobs {...params} />,
  },
  {
    path: "/user/jobs",
    renderer: (params = {}) => <UserJobs {...params} />,
  },
  {
    path: "/user/printer",
    renderer: (params = {}) => <UserPrinter {...params} />,
  },
  {
    path: "/user/support",
    renderer: (params = {}) => <UserTicketCreate {...params} />,
  },
  {
    path: "/user/buy-paper",
    renderer: (params = {}) => <UserBuyPaper {...params} />,
  },
  {
    path: "/user/checkout-success",
    renderer: (params = {}) => <UserCheckoutSuccess {...params} />,
  },
  {
    path: "/sign-in",
    renderer: (params = {}) => <SignIn {...params} />,
  },
  {
    path: "*",
    renderer: (params = {}) => <NotFound {...params} />,
  },
];

export default routes;
