import React from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = ({ title }) => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      borderRadius={2}
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box sx={{ pt: theme.spacing(3), textAlign: "center" }}>
        <CircularProgress size={isMd ? "8rem" : "6rem"} color={"primary"}  />
        <Box component={"span"} sx={{ textAlign: "center" }}>
          <Typography
            variant={"h4"}
            color={"primary"}
            sx={{ mt: theme.spacing(2) }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

Loading.defaultProps = {
  title: "Loading...",
};

Loading.propTypes = {
  title: PropTypes.string,
};

export default Loading;
