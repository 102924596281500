const stripePriceIds = {
  basic: {
    monthly: "price_1MXPibCEGONygTHsbQeZmK8r",
    annual: "price_1MXPibCEGONygTHsbRmYEjCh",
  },
  // pro: {
  //   monthly: "price_1Mks0bCEGONygTHsTjtCAjY8",
  //   annual: "price_1Mks00CEGONygTHs6j9AJ1w6",
  // },
  paper: "price_1MXPhtCEGONygTHsiJFjMQma",
};

export { stripePriceIds };
