const pages = {
  menu: [
    {
      title: "Home",
      href: "/user",
    },
    {
      title: "Support",
      href: "/support",
    },
    {
      title: "Sign out",
      href: "/sign-out",
    },
  ],
};

export default pages;
