import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";

import { paths } from "constants/urls";
import { Loading } from "shared/Loading";

const { REACT_APP_WIX_API } = process.env;

const Account = ({
  accountData: {
    attributes: { stripeCustomerId },
  },
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const wixInstanceId = localStorage.getItem("wixInstanceId");

    if (!Boolean(wixInstanceId)) {
      navigate("/sign-out");
      return;
    }

    fetch(REACT_APP_WIX_API + paths.billingPortalSession, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + new Buffer.from(wixInstanceId).toString("base64"),
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: "{}",
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        return response.json();
      })
      .then(({ sessionUrl }) => {
        window.location = sessionUrl;
      })
      .catch((status) => {
        console.log("ERROR: ", status);
        navigate("/sign-out");
      });
  }, [stripeCustomerId, navigate]);

  return <Loading />;
};

Account.propTypes = {
  accountData: PropTypes.shape({}).isRequired,
};

export default Account;
