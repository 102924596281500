import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import User from "layouts/User";
import { stripePriceIds } from "constants/stripe";
import { Paper } from "./components";

const CheckoutSuccess = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const stripePriceId = params.get("stripePriceId") || "";

  useEffect(() => {
    if (stripePriceId !== stripePriceIds.paper) {
      navigate("/user/printer");
    }
  }, [stripePriceId, navigate]);

  return (
    <User>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 247px)"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"left"}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <Typography variant={"h4"} sx={{ p: theme.spacing(4) }}>
              Checkout
            </Typography>
            <Divider />
          </Grid>
          <Grid item container>
            <Paper />
          </Grid>
        </Grid>
      </Box>
    </User>
  );
};

export default CheckoutSuccess;
