import React from "react";

import { withSessionContext } from "contexts/sessionContext";
import { Loading } from "shared/Loading";
import { Form } from "./components";

const TicketCreateState = ({ accountData }) => {
  return !accountData || Object.keys(accountData).length === 0 ? (
    <Loading />
  ) : (
    <Form accountData={accountData} />
  );
};

export default withSessionContext(TicketCreateState);
