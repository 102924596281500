import React from "react";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import Container from "components/Container";

const Printer = ({
  accountData: {
    attributes: { jobsCount, enabled },
    relationships: {
      printer: {
        data: { id: printerId },
      },
    },
  },
}) => {
  const theme = useTheme();

  return (
    <Container>
      <Box>
        <Grid container>
          <Grid item xs={12} md={5}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box>
                  <Typography variant={"h5"}>Printer attributes</Typography>
                  <Divider sx={{ my: theme.spacing(3) }} />
                  <Typography sx={{ mb: theme.spacing(2) }}>
                    Eatabit Id:{" "}
                    <Typography component={"span"} fontWeight={700}>
                      {printerId}
                    </Typography>
                  </Typography>
                  <Typography sx={{ mb: theme.spacing(2) }}>
                    Enabled:{" "}
                    <Typography component={"span"} fontWeight={700}>
                      {enabled.toString()}
                    </Typography>
                  </Typography>
                  <Typography sx={{ mb: theme.spacing(2) }}>
                    Jobs count:{" "}
                    <Typography component={"span"} fontWeight={700}>
                      {jobsCount}
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

Printer.propTypes = {
  accountData: PropTypes.shape({}).isRequired,
};

export default Printer;
