import React from "react";
import PropTypes from "prop-types";
import { format, parseISO } from "date-fns";

import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import { withSessionContext } from "contexts/sessionContext";

const chipColors = {
  received: "secondary",
  queued: "secondary",
  downloaded: "secondary",
  printed: "success",
  accepted: "success",
  expired: "error",
  rejected: "error",
};
const columns = [
  { id: "id", label: "Wix ID", align: "left" },
  {
    id: "createdAt",
    label: "Created",
    format: (value) => format(parseISO(value), "Pp"),
    align: "left",
  },
  {
    id: "jobState",
    label: "Status",
    format: (value) => (
      <Chip label={value.toUpperCase()} color={chipColors[value]} />
    ),
    align: "left",
  },
];

const Jobs = ({
  searchParams,
  searchParams: { per, page },
  setSearchParams,
  meta: { count },
  jobsData,
}) => {
  const theme = useTheme();

  const pageStart = page <= 0 ? 0 : page * per;
  const pageEnd = pageStart === 0 ? per : pageStart + per;

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setSearchParams({ ...searchParams, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchParams({ ...searchParams, page: 0, per: +event.target.value });
  };

  return (
    <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {jobsData.length > 0 ? (
            <TableBody>
              {jobsData.slice(pageStart, pageEnd).map((jobData, i) => {
                const job = { id: jobData.id, ...jobData.attributes };

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    {columns.map((column, i) => {
                      const value = job[column.id];

                      return (
                        <TableCell key={i} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align={"center"} colSpan={columns.length}>
                  <Typography variant={"h5"} sx={{ py: theme.spacing(4) }}>
                    No results
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component={"div"}
        count={count}
        rowsPerPage={per}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

Jobs.propTypes = {
  meta: PropTypes.shape({}).isRequired,
  jobsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchParams: PropTypes.shape({}).isRequired,
  setSearchParams: PropTypes.func.isRequired,
};

export default withSessionContext(Jobs);
