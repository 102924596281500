import React from "react";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import Container from "components/Container";
import { wixEatabitAppUrl } from "constants/wix";

const errors = {
  Unauthenticated: {
    title: "Unauthenticated",
    description: "There was a problem signing in.",
    resolution: "Please try again",
    resolutionUrl: { type: "window", target: wixEatabitAppUrl },
  },
};

const Error = ({ wixError }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const handleOnClick = (event) => {
    event.preventDefault();

    errors[wixError].resolutionUrl.type === "window"
      ? (window.location = errors[wixError].resolutionUrl.target)
      : navigate(errors[wixError].resolutionUrl.target);
  };

  return (
    <Container>
      <Grid container>
        <Grid
          item
          container
          alignItems={"center"}
          justifyContent={"center"}
          xs={12}
          md={6}
        >
          <Box>
            <Typography
              variant="h1"
              component={"h1"}
              align={isMd ? "left" : "center"}
              sx={{ fontWeight: 700 }}
            >
              Error
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.primary"
              align={isMd ? "left" : "center"}
            >
              {errors[wixError].title}
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              align={isMd ? "left" : "center"}
              sx={{ mt: theme.spacing(2) }}
            >
              {errors[wixError].description}
            </Typography>
            <Box
              marginTop={4}
              display={"flex"}
              justifyContent={{ xs: "center", md: "flex-start" }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleOnClick}
              >
                {errors[wixError].resolution}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

Error.propTypes = {
  wixError: PropTypes.string.isRequired,
};

export default Error;
