const paths = {
  signIn: "/login",
  passwordResets: "/password_resets",
  jobs: "/users/jobs",
  printer: "/users/printer",
  account: "/users/account",
  stripeCheckoutSessions: "/users/stripe/checkout-session",
  billingPortalSession: "/users/stripe/billing-portal-session",
  tickets: "/users/tickets",
  wixAccount: "/wix/account",
};
const mimeType = "application/json";
const httpCodes = {
  ok: 200,
  badRequest: 400,
  unauthorized: 401,
  conflict: 409,
  preconditionFailed: 412,
  internalServerError: 500,
};

export { mimeType, httpCodes, paths };
