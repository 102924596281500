/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingButton from "@mui/lab/LoadingButton";

import Container from "components/Container";
import { paths } from "constants/urls";

const { REACT_APP_WIX_API } = process.env;
const categories = [
  { value: "general", label: "General question" },
  { value: "checkout", label: "Checkout issue" },
  { value: "printer", label: "Printer issue" },
  { value: "billing", label: "Billing issue" },
];
const validationSchema = yup.object({
  subject: yup
    .string()
    .trim()
    .max(254, "Please enter a shorter subject")
    .required("Please specify a subject"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  message: yup
    .string()
    .required("Please specify a message")
    .max(2048, "Please specify a shorter message"),
  category: yup
    .string()
    .trim()
    .oneOf(
      categories.map((category) => category.value),
      "Please select a category"
    )
    .required("Please select a category"),
  printerId: yup
    .string()
    .trim()
    .nullable()
    .when("category", {
      is: (value) => value === "printer",
      then: (schema) =>
        schema
          .min(5, "Please enter at least 5 characters of the printer ID")
          .max(36, "Please enter a valid printer ID")
          .required("Please specify your printer's ID"),
    }),
});

const Form = ({
  accountData: {
    attributes: { email },
    relationships: {
      printer: {
        data: { id: printerId },
      },
    },
  },
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: "first_name last_name",
    email,
    printerId,
    message: "",
    rebooted: false,
    category: "",
    subject: "",
  };

  const onSubmit = (values) => {
    setLoading(true);

    const wixInstanceId = localStorage.getItem("wixInstanceId");

    if (!Boolean(wixInstanceId)) {
      navigate("/sign-out");
      return;
    }

    fetch(REACT_APP_WIX_API + paths.tickets, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + new Buffer.from(wixInstanceId).toString("base64"),
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        data: {
          type: "tickets",
          attributes: {
            message: values.message,
            printerId: values.printerId,
            category: values.category,
            subject: values.subject,
            email: values.email,
          },
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        return response.json();
      })
      .then(() => {
        setSuccessAlertVisible(true);
        setLoading(false);
        formik.resetForm();
        window.scrollTo(0, -100);
      })
      .catch((status) => {
        console.log("ERROR: ", status);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Container>
      <Box>
        <Box
          padding={{ xs: 3, sm: 6 }}
          width={1}
          component={Card}
          boxShadow={1}
          marginBottom={4}
        >
          <Typography variant={"h5"} paragraph sx={{ mb: theme.spacing(4) }}>
            Create a Support ticket
          </Typography>
          <Alert
            onClose={() => {
              setSuccessAlertVisible(false);
            }}
            severity={"success"}
            sx={{
              display: successAlertVisible ? null : "none",
              mb: theme.spacing(4),
            }}
          >
            <AlertTitle sx={{ fontWeight: 800 }}>Ticket created</AlertTitle>
            We will get back to you within 1 business day.
          </Alert>
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  sx={{ height: 54 }}
                  label="Printer ID"
                  type="printer_id"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="subject"
                  fullWidth
                  value={formik.values.printerId}
                  error={
                    formik.touched.printerId && Boolean(formik.errors.printerId)
                  }
                  helperText={
                    formik.touched.printerId && formik.errors.printerId
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ height: 54 }}
                  label="Email"
                  type="email"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="email"
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ height: 54 }}
                  label="Subject"
                  type="subject"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="subject"
                  fullWidth
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.subject && Boolean(formik.errors.subject)
                  }
                  helperText={formik.touched.subject && formik.errors.subject}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                >
                  <InputLabel id="select-category-label">Category</InputLabel>
                  <Select
                    labelId={"select-category-label"}
                    id={"category-select"}
                    value={formik.values.category}
                    label={"Category"}
                    onChange={(event) => {
                      event.preventDefault();
                      formik.setFieldValue("category", event.target.value);
                    }}
                  >
                    {categories.map((category, i) => (
                      <MenuItem key={i} value={category.value}>
                        {category.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.category && formik.errors.category}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Message"
                  multiline
                  rows={6}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="message"
                  fullWidth
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                  helperText={formik.touched.message && formik.errors.message}
                />
              </Grid>
              <Grid item container justifyContent={"center"} xs={12}>
                <LoadingButton
                  sx={{ height: 54, minWidth: 150 }}
                  variant={"contained"}
                  color={"primary"}
                  size={"medium"}
                  type={"submit"}
                  fullWidth
                  loading={loading}
                  disabled={loading}
                >
                  Submit
                </LoadingButton>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box>
          <Typography color="text.secondary" align={"center"}>
            We'll get back to you in 1 business day.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

Form.propTypes = {
  accountData: PropTypes.shape({}).isRequired,
};

export default Form;
