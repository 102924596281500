import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import Main from "layouts/Main";
import { Loading } from "shared/Loading";
import { paths, httpCodes } from "constants/urls";
import { Error } from "./components";

const { REACT_APP_WIX_API } = process.env;

const Install = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);
  const instanceId = urlParams.get("instanceId");
  const code = urlParams.get("code");

  const [wixError, setWixError] = useState(null);

  useEffect(() => {
    if (!instanceId || !code) {
      navigate("/not-found");

      return;
    }

    fetch(REACT_APP_WIX_API + paths.wixAccount, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        data: { code, instanceId },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        return response.json();
      })
      .then(() => {
        localStorage.setItem("wixInstanceId", instanceId);
        navigate("/user/printer");
      })
      .catch(({status}) => {
        if (status === httpCodes.conflict) {
          localStorage.setItem("wixInstanceId", instanceId);
          setWixError("AccountExists");
        }
        if (status === httpCodes.badRequest) {
          localStorage.removeItem("wixInstanceId");
          setWixError("InstallTimeout");
        }
        if (status === httpCodes.preconditionFailed) {
          localStorage.removeItem("wixInstanceId");
          setWixError("PermissionRestaurantsOrders");
        }
      });
  }, [instanceId, code, navigate]);

  return (
    <Main>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 250px)"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        height={1}
      >
        {wixError ? (
          <Error wixError={wixError} />
        ) : (
          <Loading title={"Connecting to Wix..."} />
        )}
      </Box>
    </Main>
  );
};

export default Install;
