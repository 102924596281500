import React, { useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";

const Search = ({
  searchParams,
  searchParams: { terms },
  setSearchParams,
  setLoading,
}) => {
  const theme = useTheme();

  const searchEl = useRef(null);

  const handleClear = () => {
    setLoading(true);
    setSearchParams({ ...searchParams, terms: "" });
    searchEl.current.value = "";
  };
  const handleChange = (event) => {
    event.preventDefault();

    const {
      target: { value },
    } = event;
    
    if (value && value.length >= 5) {
      setLoading(true);
      setSearchParams({ ...searchParams, terms: value });
    }
    if (value === "") {
      handleClear();
    }
  };

  // eslint-disable-next-line
  const debouncedHandleChange = useMemo(() => debounce(handleChange, 300), []);

  useEffect(() => {
    return () => {
      debouncedHandleChange.cancel();
    };
  }, [debouncedHandleChange]);

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={{ xs: "space-between", md: "flex-start" }}
      width={1}
    >
      <Box
        sx={{
          display: { md: "block" },
          flexGrow: 1,
          marginX: 2,
          "& .MuiOutlinedInput-notchedOutline": {
            border: "0 !important",
          },
          "& .MuiOutlinedInput-input": {
            padding: 1,
          },
        }}
      >
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            inputRef={searchEl}
            placeholder={"Search by Wix ID"}
            onChange={debouncedHandleChange}
            fullWidth
            sx={{
              background: theme.palette.background.paper,
              border: `1px solid ${theme.palette.divider}`,
            }}
            startAdornment={
              <InputAdornment position="start">
                <Box
                  component={"svg"}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width={24}
                  height={24}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </Box>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <ClearIcon onClick={handleClear} sx={{ cursor: "pointer" }} />
              </InputAdornment>
            }
            defaultValue={terms}
          />
          <FormHelperText id="component-helper-text">
            5 character minimum
          </FormHelperText>
        </FormControl>
      </Box>
    </Box>
  );
};

Search.propTypes = {
  searchParams: PropTypes.shape({}).isRequired,
  setSearchParams: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default Search;
