import React from "react";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import Container from "components/Container";
import { PricingOptions, Images } from "./components";

const Purchase = () => {
  const theme = useTheme();

  return (
    <Container paddingY={0}>
      <Box sx={{ position: "relative" }}>
        <Container
          position={"relative"}
          zIndex={3}
          sx={{ mb: theme.spacing(8) }}
        >
          <Box>
            <Box sx={{ mb: theme.spacing(8) }}>
              <Typography
                variant="h3"
                gutterBottom
                align={"center"}
                sx={{ fontWeight: 900 }}
              >
                Eatabit Cloud Printing
              </Typography>
              <Typography
                variant="h6"
                component="p"
                color="text.primary"
                align={"center"}
              >
                Eatabit Cloud Printers are a perfect match for Wix Restaurant
                customers who need the convenience, portability and reliability
                of printed receipts.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Images />
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box>
                  <Typography variant={"h4"} fontWeight={700}>
                    The Eatabit Cloud Printer
                  </Typography>
                  <Typography
                    variant={"subtitle1"}
                    color={"text.secondary"}
                    sx={{ mt: theme.spacing(3) }}
                  >
                    The Wix Eatabit app connects your Wix Restaurant with an
                    Eatabit Cloud Printer. The printer connects to any available
                    2.4GHz wifi network in seconds and is ready to print your
                    Wix Restaurants orders on standard paper.
                  </Typography>
                  <Box marginY={3}>
                    <Box display={"flex"}>
                      <Typography
                        variant={"h3"}
                        fontWeight={700}
                        color={"secondary"}
                      >
                        $225
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    variant={"subtitle2"}
                    color={"text.secondary"}
                    sx={{ my: theme.spacing(1) }}
                  >
                    ** If you already have an Eatabit printer, please{" "}
                    <Link
                      component={RouterLink}
                      to={"/user/support"}
                      sx={{
                        color: "#333",
                        cursor: "pointer",
                        fontWeight: 700,
                      }}
                    >
                      contact Support{" "}
                    </Link>
                    before purchasing.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <PricingOptions loading={loading} /> */}
        <PricingOptions />
      </Box>
    </Container>
  );
};

export default Purchase;
