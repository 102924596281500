import React from "react";
import PropTypes from "prop-types";

import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";

import ThemeModeToggler from "components/ThemeModeToggler";

const { REACT_APP_HTTP_SCHEME, REACT_APP_WWW_HOST } = process.env;

const Topbar = ({ onSidebarOpen, colorInvert = false }) => {
  const theme = useTheme();

  const { mode } = theme.palette;

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
    >
      <Box display={"flex"} alignItems={"center"} color={"primary.dark"}>
        <Box
          component={"img"}
          src={
            mode === "light" && !colorInvert
              ? `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/logos/32_120_light.svg`
              : `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/logos/32_120_dark.svg`
          }
          height={1}
          width={1}
        />
      </Box>
      <Box display={"flex"}>
        <Box marginLeft={{ xs: 1, md: 4 }} sx={{ my: "auto" }}>
          <ThemeModeToggler />
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }} marginLeft={2}>
          <Button
            onClick={() => onSidebarOpen()}
            aria-label="Menu"
            variant={"outlined"}
            sx={{
              borderRadius: 2,
              minWidth: "auto",
              padding: 1,
              borderColor: alpha(theme.palette.divider, 0.2),
            }}
          >
            <MenuIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
