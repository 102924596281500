import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Buffer } from "buffer";

import Box from "@mui/material/Box";

import Main from "layouts/Main";

import { Loading } from "shared/Loading";
import { paths } from "constants/urls";
import { Error } from "./components";

const { REACT_APP_WIX_API } = process.env;

const SignIn = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [wixError, setWixError] = useState(null);

  const urlParams = new URLSearchParams(location.search);
  const instance = urlParams.get("instance");

  useEffect(() => {
    let wixInstanceId;

    try {
      const result = jwt_decode(instance);

      wixInstanceId = result.instanceId;
    } catch (error) {
      console.error(error);
      setWixError("Unauthenticated");

      return;
    }

    const Authorization = Boolean(wixInstanceId)
      ? "Bearer " + new Buffer.from(wixInstanceId).toString("base64")
      : null;

    fetch(REACT_APP_WIX_API + paths.account, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        return response.json();
      })
      .then(() => {
        localStorage.setItem("wixInstanceId", wixInstanceId);
        navigate("/user");
      })
      .catch(() => {
        setWixError("Unauthenticated");
      });
  }, [instance, navigate]);

  return (
    <Main>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 250px)"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        height={1}
      >
        {wixError ? (
          <Error wixError={wixError} />
        ) : (
          <Loading title={"Signing in..."} />
        )}
      </Box>
    </Main>
  );
};

export default SignIn;
