import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SupportIcon from "@mui/icons-material/Support";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PrintIcon from "@mui/icons-material/Print";

const items = [
  {
    groupTitle: "Dashboard",
    id: "dashboard",
    pages: [
      {
        title: "Jobs",
        to: "/user/jobs",
        icon: <ReceiptIcon />,
      },
      {
        title: "Printer",
        to: "/user/printer",
        icon: <PrintIcon />,
      },
      {
        title: "Account",
        to: "/user/account",
        icon: <AccountBoxIcon />,
      },
    ],
  },
  {
    groupTitle: "Support",
    id: "support",
    pages: [
      {
        title: "Buy paper",
        to: "/user/buy-paper",
        icon: <ShoppingCartIcon />,
      },
      {
        title: "Support",
        to: "/user/support",
        icon: <SupportIcon />,
      },
    ],
  },
];

const SidebarNav = () => {
  const theme = useTheme();

  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  return (
    <Box padding={2}>
      {items.map((item, i) => (
        <Box key={i} marginBottom={3}>
          <Typography
            variant="caption"
            color={"text.secondary"}
            sx={{
              fontWeight: 700,
              textTransform: "uppercase",
              marginBottom: 1,
              display: "block",
            }}
          >
            {item.groupTitle}
          </Typography>
          <Box>
            {item.pages.map((p, i) => (
              <Box marginBottom={1 / 2} key={i}>
                <Button
                  component={RouterLink}
                  to={p.to}
                  fullWidth
                  sx={{
                    justifyContent: "flex-start",
                    color:
                      activeLink === p.to
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                  }}
                  startIcon={p.icon || null}
                >
                  {p.title}
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
      <Button
        variant={"contained"}
        color={"primary"}
        fullWidth
        component={RouterLink}
        to={"/sign-out"}
      >
        Sign out
      </Button>
    </Box>
  );
};

SidebarNav.defaultProps = {
  step: "initial",
};

SidebarNav.propTypes = {
  printerId: PropTypes.string,
};

export default SidebarNav;
