import React from "react";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import User from "layouts/User";
import { TicketCreateState } from "./components";

const UserTicketCreate = () => {
  const theme = useTheme();

  return (
    <User>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 247px)"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"left"}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Typography variant={"h4"} sx={{ p: theme.spacing(4) }}>
              Support
            </Typography>
            <Divider />
          </Grid>
          <Grid item container>
            <TicketCreateState />
          </Grid>
        </Grid>
      </Box>
    </User>
  );
};

export default UserTicketCreate;
