import React from "react";

import Box from "@mui/material/Box";
// import Stack from "@mui/material/Stack";

const { REACT_APP_HTTP_SCHEME, REACT_APP_WWW_HOST } = process.env;
const productImagePath = `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/product/`;
const mock = [
  {
    title: "Eatabit home screen",
    src: `${productImagePath}ui_autoprint.jpg`,
  },
  // {
  //   title: "Eatabit order fulfillment",
  //   src: `${productImagePath}ui_fulfillment.jpg`,
  // },
  // {
  //   title: "Eatabit order accepted",
  //   src: `${productImagePath}ui_accepted.jpg`,
  // },
  // {
  //   title: "Eatabit order rejected",
  //   src: `${productImagePath}ui_rejected.jpg`,
  // },
];

const Image = () => {
  // const [current, setCurrent] = useState(mock[0]);

  return (
    <Box>
      <Box
        sx={{
          marginBottom: 2,
          width: 1,
          height: "auto",
          "& img": {
            width: 1,
            height: 1,
            objectFit: "cover",
            borderRadius: 2,
          },
        }}
      >
        <img src={mock[0].src} alt={mock[0].title} />
      </Box>
      {/* <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        {mock.map((item, i) => (
          <Box
            key={i}
            onClick={() => setCurrent(item)}
            sx={{
              width: 80,
              height: "auto",
              cursor: "pointer",
              "& img": {
                width: 1,
                height: 1,
                objectFit: "cover",
                borderRadius: 2,
              },
            }}
          >
            <img src={item.src} alt={item.title} />
          </Box>
        ))}
      </Stack> */}
    </Box>
  );
};

export default Image;
