import React from "react";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import Container from "components/Container";

const { REACT_APP_HTTP_SCHEME, REACT_APP_WWW_HOST } = process.env;

const Processing = () => {
  const theme = useTheme();

  return (
    <Container>
      <Box>
        <Grid container spacing={theme.spacing(4)}>
          <Grid item xs={12} md={7}>
            <Box>
              <Box
                sx={{
                  marginBottom: 0,
                  width: 1,
                  height: 1,
                  "& img": {
                    width: 1,
                    height: 1,
                    objectFit: "cover",
                    borderRadius: 2,
                  },
                }}
              >
                <img
                  src={`${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/product/ui_autoprint.jpg`}
                  alt={"Eatabit Model v10 printer"}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box>
                  <Box
                    padding={1}
                    display={"inline-flex"}
                    borderRadius={1}
                    bgcolor={"secondary.main"}
                    marginBottom={theme.spacing(2)}
                  >
                    <Typography sx={{ color: "common.black", lineHeight: 1 }}>
                      Processing
                    </Typography>
                  </Box>
                  <Typography variant={"h3"} fontWeight={700}>
                    Order status
                  </Typography>
                  <Typography
                    variant={"subtitle1"}
                    color={"text.secondary"}
                    sx={{ mt: theme.spacing(3) }}
                  >
                    Your Eatabit printer has been purchased and is in the
                    process of being picked and packed. You will receive an
                    email when the order has shipped.
                  </Typography>
                  <Typography
                    variant={"subtitle1"}
                    color={"text.secondary"}
                    sx={{ mt: theme.spacing(3) }}
                  >
                    If you have any questions, please use the button below.
                  </Typography>
                  <Divider sx={{ my: theme.spacing(4) }} />
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    component={"a"}
                    href={"mailto:support@eatabit-wix.com"}
                  >
                    Contact us
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Processing;
