import React, { createContext, useState, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";

import { paths } from "constants/urls";

const SessionContext = createContext();

const { REACT_APP_WSS_API, REACT_APP_WIX_API } = process.env;

const eventTypes = {
  job: ["job-created", "job-updated"],
  account: ["account-disabled", "account-printer-assigned"],
};

const SessionContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const [accountData, setAccountData] = useState({});
  const [jobsData, setJobsData] = useState([]);

  const wixInstanceId = localStorage.getItem("wixInstanceId");
  const Authorization = Boolean(wixInstanceId)
    ? "Bearer " + new Buffer.from(wixInstanceId).toString("base64")
    : null;

  const { lastJsonMessage, sendMessage } = useWebSocket(
    REACT_APP_WSS_API,
    {
      queryParams: {
        Authorization,
      },
      reconnectAttempts: 10,
      reconnectInterval: 3000,
      retryOnError: true,
      onError: (error) => {
        console.log("Error: ", error);
      },
      shouldReconnect: () => true,
      onOpen: () =>
        setTimeout(function ping() {
          sendMessage("/ping");
          setTimeout(ping, 25000);
        }, 25000),
    },
    Boolean(Authorization)
  );

  useEffect(() => {
    if (!Boolean(Authorization)) {
      navigate("/sign-out");
      return;
    }

    fetch(REACT_APP_WIX_API + paths.account, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        return response.json();
      })
      .then(({ data }) => {
        setAccountData(data);
      })
      .catch((status) => {
        console.log("ERROR: ", status);
        navigate("/sign-out");
      });
  }, [Authorization, setAccountData, navigate]);

  useEffect(() => {
    if (!lastJsonMessage || Object.keys(lastJsonMessage).length === 0) {
      return;
    }

    const { type, detail } = lastJsonMessage;

    if (!eventTypes.job.includes(type) && !eventTypes.account.includes(type)) {
      return;
    }

    // Account message
    if (eventTypes.account.includes(type)) {
      const {
        data,
        data: {
          attributes: { enabled },
        },
      } = detail;

      enabled ? setAccountData(data) : navigate("/sign-out");
    }

    // Job message
    if (eventTypes.job.includes(type)) {
      setJobsData((jobsData) => {
        const {
          data,
          data: { id },
        } = detail;

        if (jobsData.length === 0) {
          return [data];
        }

        const jobsDataDup = jobsData.slice(0, 50);
        const jobIndex = jobsDataDup.findIndex((job) => job.id === id);

        if (jobIndex < 0) {
          return [data, ...jobsDataDup];
        } else {
          jobsDataDup.splice(jobIndex, 1, data);

          return jobsDataDup;
        }
      });
    }
  }, [lastJsonMessage, navigate]);

  return (
    <SessionContext.Provider
      value={{ accountData, setAccountData, jobsData, setJobsData }}
    >
      {children}
    </SessionContext.Provider>
  );
};

const withSessionContext = (Child) => (props) =>
  (
    <SessionContext.Consumer>
      {(context) => <Child {...props} {...context} />}
    </SessionContext.Consumer>
  );

export { SessionContextProvider, withSessionContext };
