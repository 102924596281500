import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { Buffer } from "buffer";
import { useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import { Printer } from "./components";
import Main from "layouts/Main";
import { paths } from "constants/urls";

const { REACT_APP_WIX_API } = process.env;

const Pricing = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [authenticated, setAuthenticated] = useState(false);

  const urlParams = new URLSearchParams(location.search);
  const instance = urlParams.get("instance");

  useEffect(() => {
    if (!Boolean(instance)) {
      return;
    }

    let wixInstanceId;

    try {
      const result = jwt_decode(instance);

      wixInstanceId = result.instanceId;
    } catch (error) {
      console.error(error);

      return;
    }

    const Authorization = Boolean(wixInstanceId)
      ? "Bearer " + new Buffer.from(wixInstanceId).toString("base64")
      : null;

    fetch(REACT_APP_WIX_API + paths.account, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        return response.json();
      })
      .then(() => {
        localStorage.setItem("wixInstanceId", wixInstanceId);
        setAuthenticated(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [instance, navigate]);

  return (
    <Main>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 250px)"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        height={1}
      >
        <Printer authenticated={authenticated} />
      </Box>
    </Main>
  );
};

export default Pricing;
