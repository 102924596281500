import React from "react";

import { withSessionContext } from "contexts/sessionContext";
import { Loading } from "shared/Loading";
import { Account } from "./components";

const AccountState = ({ accountData }) => {
  return !accountData || Object.keys(accountData).length === 0 ? (
    <Loading />
  ) : (
    <Account accountData={accountData} />
  );
};

export default withSessionContext(AccountState);
