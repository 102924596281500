import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import User from "layouts/User";
import { Loading } from "shared/Loading";
import { paths } from "constants/urls";
import { stripePriceIds } from "constants/stripe";

const { REACT_APP_WIX_API } = process.env;

const UserBuyPaper = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const wixInstanceId = localStorage.getItem("wixInstanceId");

    if (!Boolean(wixInstanceId)) {
      navigate("/sign-out");
      return;
    }

    const stripePriceId = stripePriceIds.paper;

    fetch(REACT_APP_WIX_API + paths.stripeCheckoutSessions, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + new Buffer.from(wixInstanceId).toString("base64"),
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({ data: { stripePriceId } }),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        return response.json();
      })
      .then(({ sessionUrl }) => {
        window.location = sessionUrl;
      })
      .catch((status) => {
        console.log("ERROR: ", status);
        // navigate("/sign-out");
      });
  }, [navigate]);

  return (
    <User>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 247px)"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        height={1}
        sx={{ p: theme.spacing(4) }}
      >
        <Grid container spacing={6}>
          <Grid item container>
            <Loading />
          </Grid>
        </Grid>
      </Box>
    </User>
  );
};

export default UserBuyPaper;
