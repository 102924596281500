import React from "react";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import User from "layouts/User";
import { AccountState } from "./components";

const UserAccount = () => {
  const theme = useTheme();

  return (
    <User>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 247px)"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        height={1}
        sx={{ p: theme.spacing(4) }}
      >
        <Grid container spacing={6}>
          <Grid item container>
            <AccountState />
          </Grid>
        </Grid>
      </Box>
    </User>
  );
};

export default UserAccount;
